

<script>
  import { goto, url } from "@sveltech/routify";
  //import 'bulma/css/bulma.css'
  import '@fortawesome/fontawesome-free/css/all.css'
  



  //import wranglegraphql from  'wrangle-gql' 
 // document.title = "Settings - Cowdog App";
  //meta.title =  "Settings - Cowdog App";



  // let email = "";
  // let password = "";
  // let progress = false;
  // async function login() {
  //   progress=true;
  //   let vars = await wranglegraphql.loginCustomer( email, password );
  //   //$user=;
  //   user.set({email: email, token: vars.data.loginCustomer});
  //   console.log($user, vars);
  //   /** We want to $goto our current location.
  //    *  Since we're now logged in, we shouldn't be redirected to this login page again.
  //    * **/
  //   $goto(window.location.href);
  // }
  let user = {} ;
  // let first;
  // let last  ;
  // let email  ;
  // let password;
   user.plan_id= 263082484618494484;
   user.key= 'KEY-6qatjqWF9qZA';
  let show_confirm = false;
  let progress = false;

  async function signup(e) {
      if (e){e.preventDefault()};
      progress=true;
      let sp = JSON.stringify(user);
      let endpoint = 'https://api.cowdog.app/v1/user/signup'; 
      let json;
      console.log("signup ", user, sp,endpoint);
      try {
        var res = await fetch(endpoint, {
          body: sp,
          method: "POST"
        });
        json = res.json();
        console.log(json);
        

      } catch (err) {
        console.error("fetch failed",err);
      }
    


      progress=false;
      show_confirm = true;

    }



</script>
<style>
.signup { max-width: 900px;  margin: auto;  margin-top: 20vh; }
.backup{
      cursor: pointer;
      margin-right: 1em;
}
.card-header-title{font-size: 150%;}
</style>




<div  class="signup card" >
  <header class="card-header">
  <h1 class="card-header-title">Signup</h1>
  </header>
  <div class="card-content" class:is-hidden={!show_confirm} >
   <p>Please check your email for our email, and verify your address!</p>
  </div>


  <div class="card-content" class:is-hidden={!progress}>
    <progress class="progress is-small is-primary" max="100">15%</progress>
  </div>
  <div class="card-content" class:is-hidden={progress || show_confirm}>
   <form  on:submit={signup} >
      <div class="field  is-horizontal "> 
        <div class="field-label  is-large"> <label class="label is-large" for="first">Name</label>    </div>
        <div class="field-body ">
          <div class="field"><div class="control "><input class="input is-large invalid" type="text" id="first" name="first"  autocomplete="given-name" placeholder="First"  required=""  autofocus bind:value={user.first}></div></div>
          <div class="field"><div class="control "><input class="input  is-large" type="text" id="last" name="last" placeholder="Last"  required=""  autocomplete="family-name" bind:value={user.last}></div></div>
        </div>
      </div>
      <div class="field is-horizontal">
         <div class="field-label  is-large"><label class="label  is-large">Email</label></div>
          <div class="field-body ">
          <div class="field"> <div class="control  has-icons-left">
          <input inputmode="email" name="email" id="email"  class="input  is-large" type="email" placeholder="Email"  autocomplete="email" required    bind:value={user.email}  />
          <span class="icon is-small is-left">
              <i class="fas fa-envelope"></i>
          </span>
          </div></div>
        </div>
      </div>
      <div class="field is-horizontal">
         <div class="field-label  is-large"><label class="label  is-large">Password</label></div>
        <div class="field-body "> 
        <div class="field">
        <div class="control  has-icons-left">
          <input required class="input  is-large" type="password" name="password"  id="password" placeholder="Password" autocomplete="new-password"  bind:value={user.password} />
          <span class="icon is-small is-left">
            <i class="fas fa-lock"></i>
          </span>
        </div>
        </div>
        
        </div>
      </div>

      <div class="field">
        <div class="control">
            <input type="submit" class="input button is-large is-primary" value="Submit">
        </div>
      </div>
    </form>
  </div>
</div>


