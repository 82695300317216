

<script>
  import { user } from "../_store.js";
  import { goto, url } from "@sveltech/routify";
 // import 'bulma/css/bulma.css'
  import '@fortawesome/fontawesome-free/css/all.css'
  //import wranglegraphql from  'wrangle-gql' 
 // document.title = "Settings - Cowdog App";
  //meta.title =  "Settings - Cowdog App";
//conm
 async function logout() {
   console.log("logout")
   user.set({});
   
   $goto($url('/app'));
 }

  // let email = "";
  // let password = "";
  // let progress = false;
  // async function login() {
  //   progress=true;
  //   let vars = await wranglegraphql.loginCustomer( email, password );
  //   //$user=;
  //   user.set({email: email, token: vars.data.loginCustomer});
  //   console.log($user, vars);
  //   /** We want to $goto our current location.
  //    *  Since we're now logged in, we shouldn't be redirected to this login page again.
  //    * **/
  //   $goto(window.location.href);
  // }
  let emails = [] ;
  if ($user  && $user.addresses && $user.addresses.data ){
    for (let addy of  $user.addresses.data){
    console.log("showEmails", addy)
    emails.push(addy.email)
    }
    
  }
  console.log($user, $user.addresses, $user.addresses.data)
</script>
<style>
.settings { 
  min-width: 25%;
 margin: auto; 
  margin-top: 20vh;
   max-width: 800px; }
.backup{
      cursor: pointer;
      margin-right: 1em;
}
</style>




<div  class="settings card" >
  <header class="card-header">
 
  <h1 class="card-header-title">      <a href = "/app"><i
                  class="fas fa-chevron-left backup"
                   />&nbsp</a>   <a href = "/app">Settings</a> </h1>
  </header>
  <div class="card-content">
     <table class="table is-fullwidth is-striped" >
        <tr><td><h2 style="font-size: 1.3em; font-weight:bold;">{$user.first} {$user.last}</h2></td></tr>
        <tr><td>{$user.email}</td></tr>
        <tr><td style="padding: 1.5em 0.75em;">
            {#if emails.length}
            <ul  >
  {#each emails  as email}
<li>{email}</li>
  {/each}
  </ul>
            {:else}
              <i >No Inbound Addresses</i> - <a href="/app/address">add one!</a>
            {/if}
        
        </td></tr>
     </table>
    <form on:submit={logout} >  
      <div class="field">
        <div class="control">
            <button class="input button  is-primary" on:click={logout} >Log out</button>
        </div>
      </div>
    </form>
  </div>
</div>


