<script>
  //TODO: mark all items as read
  // TODO: move text to s3
  // check timestamsps navigate to main list
  //store items in indexdb
  // just retrieve the item


  import sanitizeHtml from 'sanitize-html';    // webshare in the file
  //import 'node_modules/readable-css/scss/readable';
  //import { leftover, meta } from "@sveltech/routify";



  import { user, loadUser, feeds, loadFeeds } from "./_store";
  import { onMount } from "svelte";
  import wranglegraphql from "wrangle-gql";

  import FeedItemsPanel from "./FeedItemsPanel.svelte";


  let feedid;
  let itemid
  //$: [feedid, itemid ] = $leftover.split("/");

  let canShare = false;
  let article;
  let articlecolumn;
  //document.title = "Cowdog App";
  //meta.title =  "Cowdog App!";

  $: console.log("USER IS ", $user);
  //$: console.log("currentItem changed index",currentItem)

  //let feeds;
  let feedsPromise;
  let currentItem;
  let currentFeed;

  if ($user.token && ! $user.first) {

    console.log("user token but no first name", $user);
    let userPromise = fetchUserData($user.token);
  }

  if ($user.token && !$feeds) {

    console.log("user token but no feeds")
    loadFeeds();

    if (!$feeds) {
      feedsPromise = fetchFeeds($user.token);
      console.log("fetchingFeeds")
    }
  }


async function reloadFeeds (){

  feedsPromise = fetchFeeds($user.token);
}


  
 async function fetchUserData(token) {
    let userData = await wranglegraphql.getUser(token);
        console.log("userData",userData)

    userData.token = token;

    user.set(userData);
    console.log("end of fetch userData",userData)
    return userData;
  }


  async function fetchFeeds(token) {
    let vars = await wranglegraphql.getFeeds(token);

    const processedVars = preProcessFeeds(vars);

    feeds.set(processedVars);
    return vars;
  }

  function preProcessFeeds(gqlfeeds) {
    for (let feed of gqlfeeds) {
      if (feed.items && feed.items.data.length) {
        feed.all = 1;
        feed.unread = feed.items.data.filter(item => !item.read).length;
        feed.starred = feed.items.data.filter(item => item.starred).length;

      } else {
        feed.all = 0;
      }
    }
    return gqlfeeds;
  }

  let allItems = [];
  let unreadItems = [];
  let starredItems = [];

  let mode = "unread";  // valid "" for all, "unread", "starred"

  //TODO: mark feeds as unread and starred based on items

  function populateAllItems() {
    if (!allItems.length) {
    //  console.log("allitenms start", allItems);

      for (const feed of $feeds) {
        if (feed.items && feed.items.data.length) {
          allItems = allItems.concat(feed.items.data);
          if (!feed.items.data.read) {
            unreadItems = unreadItems.concat(feed.items.data);
          }
          if (feed.items.data.starred) {
            starredItems = starredItems.concat(feed.items.data);
          }
        }
      }
    //  console.log("allitenms assembled", allItems);
    }
  }

  function clickShowAll() {
    if (!allItems.length) {
      populateAllItems();
    }
 //   console.log("allitenms feed");
    if (mode == "unread") {
      currentFeed = {
        title: "Unread Items",
        items: { data: allItems.filter(item => !item.read) }
      };
    } else if (mode == "starred") {
      currentFeed = {
        title: "Starred Items",
        items: { data: allItems.filter(item => item.starred) }
      };
    } else {
      currentFeed = { title: "All Items", items: { data: allItems } };
    }
  }

  function share() {

    if (navigator.share && currentItem) {
      if (navigator.canShare ) {
        console.log("canShare");
        const file = new File([currentItem.description], currentItem.title+'.html',  {type: "text/html", lastModified: new Date()});
        const filesArray =  [file] ;
        if ( navigator.canShare({ files: filesArray})) {
         navigator
        .share({
              files: filesArray,
              title: currentItem.title,
        })
        .then(() => console.log("Successful share of file"))
        .catch(error => console.log("Error sharing", error));


        }
      }else{
          let text ="";
          let selection = window.getSelection();  // this seems to not work in safari

          if  (selection && selection.toString() ) {
              text = selection.toString()
          }else{
             text = sanitizeHtml(currentItem.description, { allowedTags: [] } ); 
          }
        navigator  
        .share({
          title: currentItem.title,
          text: "I was reading the ''" + currentFeed.title + "' in https://cowdog.app and thought you might be interested..\n\n" + text
        })
        .then(() => console.log("Successful share"))
        .catch(error => console.log("Error sharing", error));
    }
        
      }



     
  }

  function toggleRead() {
    if (currentItem) {
      wranglegraphql.setItemRead($user.token, currentItem._id, !currentItem.read);
      currentItem.read=!currentItem.read;
      if (currentItem.read)  {
          // marking as read, reduces unread 
          if (currentFeed.unread){ currentFeed.unread =  currentFeed.unread - 1;}
      } else {
         // marking as unread increase feed unread count
         currentFeed.unread =  currentFeed.unread + 1;
      }
    }
  }

  function toggleStar() {
    if (currentItem) {

      wranglegraphql.setItemStarred(
        $user.token,
        currentItem._id,
        !currentItem.starred
      );
      currentItem.starred= !currentItem.starred;
     if (currentItem.starred)  {
          if (currentFeed.starred){ currentFeed.starred =  currentFeed.starred + 1;}
     } else {
         currentFeed.starred =  currentFeed.starred - 1;
     }
    }
  }

  //$: console.log("final feeds", $feeds);

  //$: console.log("final current", currentItem);
  $: console.log(" currentFeed ", currentFeed);




if ($user.token && !$feeds) {

  console.log("user token but no feeds -- end")
    loadFeeds();

    if (!$feeds) {
      feedsPromise = fetchFeeds($user.token);
      console.log("fetchingFeeds")
    }
}






  onMount(() => {
    console.log("onMount");
   


    if (!user || !$user.email) {
      console.log("onMount load user ");
      loadUser();
    }
    if (!feeds) {
      console.log("onMount loadFeeds ");
      loadFeeds();
    }
    if (navigator.share) {
      canShare = true;
    }

    console.log("onMount done");

  });


function scrollItemToTop (){
  if (article) {
                article.scrollTop = 0;
                articlecolumn.scrollTop=0;
              }


}

function handleKeydown (event ){

  let key = event.key;
  let keyCode = event.keyCode;
  console.log(key,keyCode);
  if (keyCode == '38') {
        // up arrow
    }
    else if (keyCode == '40') {
        // down arrow
    }
    else if (keyCode == '37') {
       // left arrow
       if (currentItem) {        
         currentItem=null;
         } else if (currentFeed){
           currentFeed=null;
        } // and select the next feed item, or feed 


    }
    else if (keyCode == '39') {
       // right arrow
    }

}




  </script>

<style>
  img[alt]:-moz-broken {
    display: none;
  }
  .feedcolumn {
    padding-right: 0;
    width: 25%;
    max-width: 25%;
    min-width: 350px;
    flex-grow: .25;
  }

  .navcolumn {
    max-width: 350px;
    width: 33%;
    padding-right: 0;
    flex-grow: .25;

  }

  .contentcolumn {
    padding-left: 0;
  }

  nav.panel {
    border-radius: 0;
    min-height: 100%;
  }
  nav.panel.feeds {
    overflow-x: hidden;
  }

  .panel {
    border-radius: 0;
    box-shadow: none;

  }

  .panel .panel-heading {
    border-radius: 0;
    position: sticky;
    top: 0;
    
}
  
  #article {
    width: 100%;
    height: 100%;
    border-radius: 0;

    background: #F5F2E7;
    color: rgb(50, 50, 50);
    /* max-width: 800px; */
overflow-y: scroll;
overflow-x: hidden;
overflow-wrap: break-word;


  }
  .columns {
    /* max-height: 97vh; */
    flex: 1;
    width: 100%;
  }
  .column {
    /* min-height: 94vh; */
    max-height: 100vh;
    overflow: scroll;
    padding-bottom: 0;
  }

  .column:not(:first-child) {
    /* border-left: 1px solid #ededed; */
    border-left: 1px solid #ccc;
    padding-left: 0;
  }

  #feeds-heading,
  #item-heading {
    display: flex;
    justify-content: space-between;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    height: 100vh;
    overflow: hidden;
    width: 100%;
  }
  #search {
    padding: 0.5em 0.75em !important;
  }

  .is-read {
    color: #888;
  }

  #currentItem-actions i {
    margin-left: 1em;
    cursor: pointer;
  }
  #currentItem-actions i:hover {
    text-shadow: 2px 2px #ffd700;
  }

  #currentItem-actions {
    display: flex;
    width: initial;
    align-content: space-around;
  }

.backup{
      cursor: pointer;
      margin-right: 1em;
}
.currentItem {
  padding: 1em 2em;
  overflow-wrap: anywhere;
}



.feedcolumn-left {max-width: 25%;}

.panel {
  width: 100%;
}


.feedcolumn{
max-width: 100%;
width: 100%;
}
.navcolumn{
max-width: 100%;
width: 100%;
} 



@media screen and (max-width: 1215px) {
  .feedcolumn{
  max-width: 100%;

  }
    
}
@media screen and  (max-width: 1215px) {

  .feedcolumn-left {
    max-width: 25%;
    min-width: unset;
  }
}
@media screen and (max-width: 1215px) {
  .column {
  display: inline-flex;
  width: 50%;
  }

}

@media screen and (max-width: 768px) {
  .feedcolumn{
  max-width: 100%;
  width: 100%;
  }
  .navcolumn{
  max-width: 100%;
  width: 100%;
  }  

  .column {
  display: inline-flex;
  width: 100%;
  }

}

@media screen and (max-width: 1215px) {
  .is-hidden-narrow {
  display: none;
  }
  .feedcolumn{
    flex-grow: 1;
  }
}

.item-wrapper 
{
height: 100%;
width: 100%;
overflow: scroll;
}

#article, #articlecolumn {  scroll-behavior: smooth;}



</style>
<svelte:window on:keydown={handleKeydown}/>
<div class="wrapper">

  {#await feedsPromise}

    <div class="box" style="width: 80%; max-width:800px; margin:auto;">
      <h1 class="title">Loading</h1>
      <progress class="progress is-large is-primary" max="100">15%</progress>
    </div>
  {:then data}

    {#if feeds}
      <div class="columns">
        <div class="column navcolumn" class:is-hidden-narrow={currentFeed != null} >
          <nav class="panel feeds">
            <p class="panel-heading" id="feeds-heading">
              Inbox
              <span class=" is-right">
               <span class="is-right" style="margin-right: 10px;">
                <a on:click|preventDefault={reloadFeeds}><i class="fas fa-sync" title="sync"></i></a>
              </span>
              <span class=" is-right">
                <a href="/app/settings" title="settings"><i class="fas fa-user-cog" aria-hidden="true" /></a>
              </span>
              </span>
            </p>
            <!-- <div class="panel-block" id="search">
    <p class="control has-icons-left">
      <input class="input" type="text" placeholder="Search">
      <span class="icon is-left">
        <i class="fas fa-search" aria-hidden="true"></i>
      </span>
    </p>
  </div> -->
            <p class="panel-tabs">
              <a
                class:is-active={!mode}
                on:click={e => {
                  console.log('Mode=', mode);
                  mode = '';
                }}>
                All
              </a>
              <a
                class:is-active={mode == 'unread'}
                on:click={e => {
                  console.log('Mode=', mode);
                  mode = 'unread';
                }}>
                Unread
              </a>
              <a
                class:is-active={mode == 'starred'}
                on:click={e => {
                  console.log('Mode=', mode);
                  mode = 'starred';
                }}>
                Starred
              </a>
            </p>

            <a class="panel-block is-active" on:click={clickShowAll}>
              <span class="panel-icon">
                <i class="far fa-eye" aria-hidden="true" />
              </span>
              All
            </a>

            {#each $feeds as feed}
              <!-- { # if feed.items.data.length} -->
              {#if ((! mode && feed.all )  ||  (mode == "unread" && feed.unread)  ||  (mode == "starred" && feed.starred) )}

                <a
                  class="panel-block is-active"
                  on:click={e => {
                    currentFeed = feed;
                    currentItem= null;
                    console.log('settinmg current fede', feed);
                  }}>
                  <span class="panel-icon">
                    <i class="fas fa-layer-group" aria-hidden="true" />
                  </span>
                  {feed.title}
                </a>
              {/if}
            {/each}

          </nav>
        </div>






        <div
          class="column feedcolumn"
          class:is-hidden-mobile={!currentFeed || currentItem }
          class:feedcolumn-left={currentFeed}  
          >
<!--           class:is-hidden-narrow={currentItem} -->
          <FeedItemsPanel
            bind:currentFeed
            bind:currentItem
            {mode}
            on:newArticle={e => {
              if (article) {
                article.scrollTop = 0;
                articlecolumn.scrollTop=0;
              }
            }} />

        </div>














        <div class="column contentcolumn "  id="articlecolumn" bind:this={articlecolumn}
           class:is-hidden-mobile={!currentItem}
           class:is-hidden-narrow={!currentFeed}
        >
          <div
            class=" panel "
  
            >

            <p class="panel-heading" id="item-heading">
              {#if currentItem}
                <i
                  style="padding-top: 4px;"
                  class="fas fa-chevron-left is-hidden-desktop backup"
                  class:is-hidden={!currentItem}
                  on:click={e => {
                    currentItem = null;
                  }} />
                <span on:click={scrollItemToTop}>
                  {@html currentItem.title || ''}
                </span>
                <span class="is-hidden-desktop">&nbsp;</span>
                <span class="panel-icon is-right" id="currentItem-actions">
                <i
                  class="fas fa-star"
                  aria-hidden="true"
                  class:has-text-link={currentItem.starred}
                   alt="starred toggle"

                  on:click={toggleStar} />
                <i
                  class="fas fa-book-open"
                  class:has-text-link={!currentItem.read}
                  aria-hidden="true"
                  on:click={toggleRead}
                   alt="read/unread toggle"
                   />
                <i
                  class="fas fa-arrow-circle-up"
                  aria-hidden="true"
                  class:is-hidden={!canShare}
                  on:click={share} 
                  alt="share"

                  />
              </span>
              {:else}&nbsp;Item{/if}

            </p>

            {#if currentItem}
            <div class="item-wrapper"            id="article"
            bind:this={article}>
            <div class="currentItem readable-content ">
            {#await currentItem.readable }
            {:then readable}
                             {@html readable}
             {:catch error}
	              <p style="color: red">{error.message}</p>
            {/await}
            </div>
            </div>
            {/if}
          </div>
        </div>

      </div>
    {:else}
      <h1>No feeds</h1>
    {/if}

  {/await}

</div>
