<script>
    import {  url, meta } from "@sveltech/routify";
    import HeaderNav from "./_components/HeaderNav.svelte";
    meta.title = "Home - Cowdog App";

</script>
<style>
.is-cowdog-gradient {
background-image: linear-gradient(141deg, rgba(92, 102, 122, 0.62) 0%, 6%, rgb(178, 111, 75) 20.18779367208481%, 87%, rgb(226, 224, 225) 100%);

}
.cowdoglink{color:white}  
.cowdoglink:hover{color:white; text-decoration: none;   }  
</style>
<HeaderNav/>


<section class="hero is-fullheight-with-navbar is-cowdog-gradient is-bold">
 <div class="hero-body"><div class="container">
<div style="display: flex;"><img src="/cowdog.svg" style="max-height: 225px;" alt="The Cowdog">
<div style="display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;margin-left: 20px;/*  */;">
<h1 class="title"><a href="/app"  class="cowdoglink" style="color: #FFF ">Cowdog</a></h1> <h2 class="subtitle" style="margin-bottom: 0px;">Let's wrangle some email!</h2> <h2 class="subtitle">Cowdog herds newsletters out of your inbox.</h2></div></div>

</div></div>
</section>
