

<script>
    import {  url, meta, isActive } from "@sveltech/routify";
    let menuactive = false;

</script>

<nav class="navbar" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <a class="navbar-item" href="/">
      <img src="/cowdog-simple.svg" width="32" height="32" alt="small logo">
    </a>

    <a role="button" class="navbar-burger burger" 
    aria-label="menu" aria-expanded="false" data-target="navbarBasicExample"
    on:click={e => { menuactive =  !menuactive } }
    class:is-active={menuactive}
    >
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="navbarBasicExample" class="navbar-menu"
        on:click={e => { menuactive =  !menuactive } }
        class:is-active={menuactive}
    >
    <div class="navbar-start">
      <a class="navbar-item"  class:is-hidden={$isActive("/")} href="/">
        Home
      </a>

  
      <div class="navbar-item has-dropdown is-hoverable">
        <a class="navbar-link">
          More
        </a>

        <div class="navbar-dropdown">
          <!-- <a class="navbar-item">
            About
          </a> -->
          <a class="navbar-item"  href="mailto:support@cowdog.app">
            Contact
          </a>
          <hr class="navbar-divider">
          <a class="navbar-item" href="mailto:support@cowdog.app">
            Report an issue
          </a>
        </div>
      </div>
    </div>

    <div class="navbar-end">
      <div class="navbar-item">
        <div class="buttons">
          <a class="button is-primary" href="/signup">
            <strong>Sign up</strong>
          </a>
          <a class="button is-light" href="/app">
            Log in
          </a>
        </div>
      </div>
    </div>
  </div>
</nav>
