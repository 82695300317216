

<script>
 import { params, meta } from '@sveltech/routify'  
 export let id;
 let error ;
 console.log($params, id ) ;
 meta.title = "Confirm Email - Cowdog App";
 import wranglegraphql from  'wrangle-gql' 
  let confirmed = false;
  if (id ){
     console.log("id", id) 
     confirm (id);
  }else{
     console.log("NO id") 
  }
  async function  confirm(id ){

      let vars = await wranglegraphql.confirmCustomer( id);
      console.log("postgraphql" , vars, id);
      if (!vars || vars.errors){
          console.log("errors" , vars.errors[0].message);
          error= vars.errors[0].message;
          if (error == "Set not found.") {
            error= "Invalid or already redeemed code.";

          }


      }else{
          confirmed = true;

      }
  }
 
</script>
<style>
.confirmbox {

width: 80%; max-width:800px; margin:auto; margin-top: 20vh;
}
</style>


    <div class="box confirmbox" class:is-hidden={confirmed || error}>
      <h1 class="title">Confirming {id}</h1>
      <progress class="progress is-large is-primary" max="100">15%</progress>
    </div>


    <div class="box confirmbox" style="width: 80%; max-width:800px; margin:auto; margin-top: 20vh"  class:is-hidden={!confirmed || error}>
      <h1 class="title">Confirmed!</h1>
      <p>Your account has been confirmed.  Now you can <a href="/app/address">login</a> and get started!</p>
    </div>


<article class="message is-danger confirmbox"  class:is-hidden={!error}>
  <div class="message-header">
    <p>Error</p>
   
  </div>
  <div class="message-body">
   {error}  </div>
</article>

    