<script>
export let currentFeed;
export let currentItem;
export let mode;
import FeedItemPanelItem from './FeedItemPanelItem.svelte'
import { user } from "./_store";

// TODO: readability not sanatize,  possibly on store not display

// switch to https://github.com/mozilla/readability       "readability": "github:mozilla/readability",  // const Readability = require("readability");


import wranglegraphql from 'wrangle-gql' 

//var read = require('node-readability');


//$: console.log("current changed",currentItem)



function backup(e) {

currentFeed = null;currentItem = null; console.log("backup  feed")
}


function mark_all_read(){
  if (!mode){
    return;
  }
  if (currentFeed){
      for (let item of currentFeed.items.data ){
          item.read = true;
          wranglegraphql.setItemRead($user.token, item._id, true);
      }
      currentFeed.unread =0;
      currentFeed = null;
      currentItem = null;
  }
//  if (mode == "unread") {
  //  currentFeed = {
    //  title: "Unread Items",
    //  items: { data: allItems.filter(item => !item.read) }
  //  };
//  }
}


console.log("mode", mode);



</script>






<style>
.panel {box-shadow: initial;}
.panel .panel-heading {
border-radius: 0;
display: flex;
justify-content: space-around;
position: sticky;
top: 0;
}

.backup{
      cursor: pointer;
}


@media screen and (max-width: 1215px) {
.panel .panel-heading {
  justify-content: space-between;

}


}

.panel {
  width: 100%;
}
.panel-heading-title {
  cursor: pointer;
}


.panel .panel-heading.has-mode{
  justify-content: space-between;
}
</style>






<nav class="panel onefeed">

  <p class="panel-heading" class:has-mode={mode}>
  {#if currentFeed}
   <i style="padding-top: 4px;" class="fas fa-chevron-left is-hidden-widescreen backup" 
   class:is-hidden={!currentFeed}  on:click={backup}></i>  
   <span>{currentFeed.title || ""}</span> 
    {#if mode || currentFeed.webunsubscribe }
    <span>
        {#if mode} <a on:click|preventDefault={mark_all_read} ><i class="far fa-check-circle" ></i></a> {/if}
         {#if currentFeed.webunsubscribe } <a href="{ currentFeed.webunsubscribe }" target="_blank" ><span class="fa-stack fa-2x">
  <i class="fas fa-envelope fa-stack-1x"></i>
  <i class="fas fa-ban fa-stack-2x"></i>
</span></a> {/if}
     </span>
    {:else}   
       <span class="is-hidden-widescreen">&nbsp;</span>
     {/if}
  {:else}
    <span class="panel-heading-title" on:click={backup}>Feed</span>
    {/if}
  </p>
  {#if currentFeed}

 {#each currentFeed.items.data as item}
 {#if mode && mode=="unread" &&  (currentItem === item ||  ! item.read) }
<FeedItemPanelItem  {item} bind:currentItem={currentItem}   bind:currentFeed on:newArticle />
{:else if  mode && mode=="starred" &&   (currentItem === item || item.starred ) }
<FeedItemPanelItem  {item} bind:currentItem={currentItem}   bind:currentFeed on:newArticle />
 {:else if !mode}
<FeedItemPanelItem  {item} bind:currentItem={currentItem} bind:currentFeed
  on:newArticle />
 {/if}

{/each}
{/if}
</nav>


