'strict'

const fetch = require('node-fetch');
const endpoint = 'https://graphql.fauna.com/graphql';
const key = 'fnADodx5gaACE_93Zvwvkh0dkTY1wnacPbBD80c8'; // key with "public" permissions  IE just login
let auth = `Bearer ${key}`;

//const gql = require('nanographql')

var getOpname = /(query|mutation) ?([\w\d-_]+)? ?\(.*?\)? \{/;

function gql(str) {
    str = Array.isArray(str) ? str.join('') : str;
    var name = getOpname.exec(str);
    return function (variables) {
        var data = { query: str };
        if (variables) data.variables = variables;
        if (name && name.length) {
            var operationName = name[2];
            if (operationName) data.operationName = name[2];
        }
        return JSON.stringify(data);
    };
}
 
{
    let token;

    module.exports.createAddress = async function (token, mail) {
        var query = gql`
            mutation AddAddress($mail: String!) {
                addAddress(input:  $mail ){
                  email
                }
            }
        `;
        let json = await runQuery(token, query({mail: mail}));

        return json;
    };

    module.exports.loginCustomer = async function login(email, password) {
        email.replace(/"/, '"');
        password.replace(/"/, '"');

        var query = `mutation LoginCustomer{
  loginCustomer(input: {
    email: "${email}"
    password: "${password}"
  })
}
`;

        var query2 =
            'mutation LoginCustomer { \
  loginCustomer(input: {\
    email: "${email}" \
    password: "${password}"\
  })\
}';

        var json;
        //query({ email: email, password: password }),

        try {
           // console.log(query);
            //

            var res = await fetch(endpoint, {
                body: JSON.stringify({ query: query }),

                method: 'POST',

                headers: {
                    Authorization: auth,
                    'Content-Type': 'application/json',
                    ///-H 'Content-Type: application/json' -H 'Accept: application/json' -H 'Connection: keep-alive' -H 'DNT: 1'
                },
            });
            json = await res.json();
            console.log('wrangle res2', json); // query({ email: email, password: password })
        } catch (err) {
            console.error(err);
        }
        return json;
    };

    module.exports.createUser = async function (
        first,
        last,
        email,
        password,
        key
    ) {
        let token = key;
        return 1;
    };

    module.exports.getItems = async function (token, address, read, starred) {
        var query = gql`
            query($name: String!) {
                item(name: $name) {
                    releaseDate
                }
            }
        `;

        try {
            var res = await fetch(endpoint, {
                body: query({ name: 'Back to the Future' }),
                method: 'POST',
            });
            var json = res.json();
            console.log(json);
        } catch (err) {
            console.error(err);
        }
    };



    module.exports.getUser = async function (token) {
        var query = gql`
  
  query getCustomer {
        allCustomers {
            data {
               _id
              role	
              first
              last
              state
              email
              email_list
              addresses{
                data{
                  email
                }
              }
               plan{
                _id
                yearly_price
                name
                display_yearly_price
              
              } 
              
                
            }
        }
    }
        `;
        let json = await runQuery(token, query());
        return json.data.allCustomers.data[0];
    };
    



// removed                                 description


    module.exports.getFeeds = async function (token) {
        var query = gql`
            query FindAllFeeds {
                allFeeds {
                    data {
                        url
                        title
                        items {
                            data {
                                _id
                                created_at
                                title
                                read
                                starred
                            }
                        }
                    }
                }
            }
        `;
        let json = await runQuery(token, query());
        return json.data.allFeeds.data;
    };

    module.exports.getAddresses = async function (token) {
        var query = gql`
        query FindAllAddresses {
          allAddresses {
            data {
              email
            }
        }
      `;
        let json = await runQuery(token, query());
        return json.data.allFeeds.data;
    };

    module.exports.setItemRead = async function (token, item, read) {
        var query = gql`
            mutation ItemRead($id: ID!, $read: Boolean!) {
                itemRead(id: $id, read: $read) {
                    _id
                    read
                }
            }
        `;
        let q = query({ id: item, read: read });
        let json = await runQuery(token, q);
        console.log('setRead q', { id: item, read: read }, json, q);

        return json;
    };

    module.exports.setItemStarred = async function (token, item, starred) {
        var query = gql`
            mutation ItemStarred($id: ID!, $starred: Boolean!) {
                itemStarred(id: $id, starred: $starred) {
                    _id
                    starred
                }
            }
        `;
        let q = query({ id: item, starred: starred });
        let json = await runQuery(token, q);
        console.log(
            'setItemStarred q',
            { id: item, starred: starred },
            json,
            q
        );

        return json;
    };

    module.exports.delete = async function (token, itemid) {
        var query = gql`
            mutation ItemRemove($id: ID!) {
                itemRemove(id: $id) {
                    _id
                }
            }
        `;
        let json = await runQuery(token, query({ id: itemid }));
        return json.data.itemRemove.data;
    };

    module.exports.confirmCustomer = async function (code) {
        console.log("this.confirmCustomer", code);
        let token = "fnADqO5GPHACEnLdO6bDcupxocaZGzFA8n4jkDcU";
        var query = gql`
            mutation ConfirmCustomer($code: String!) {
                confirmCustomer(input: $code) {
                    _id
                    role
                    state
                }
            }
        `;
        let json = await runQuery(token, query({code: code}));
        return json;
    };






    const runQuery = async function (token, body) {
        let bearer = 'Bearer ' + token;
        console.log(body);
        if (!token) {bearer = auth; console.log("no token",bearer  )}
        try {
            var res = await fetch(endpoint, {
                body: body,
                method: 'POST',
                headers: {
                    Authorization: bearer,
                },
            });
            var json = await res.json();
            console.log(json);
            return json;
        } catch (err) {
            console.error(err);
        }
    };
}

/*  


 //curl 'https://graphql.fauna.com/graphql' -H 'Accept-Encoding: gzip, deflate, br' -H 'Content-Type: application/json' -H 'Accept: application/json' -H 'Connection: keep-alive' -H 'DNT: 1' -H 'Origin: https://dashboard.fauna.com' -H 'authorization: Basic Zm5BRG8ybHJLZEFDRWdSZ01rSkNZQU5jeWphaElUV0s1dXJ1ZWRVQjp3cmFuZ2xlOnNlcnZlcg=='

//--data-binary '{"query":"mutation ItemRead($id: ID!){\n      itemRead(id: $id,  read: true ){\n      \t_id\n        read\n    }\n   }\n  \n \t","variables":{"id":"260833338040779283"}}' --compressed
//              '{"query":"mutation ItemRead($id: ID!){\n      itemRead(id: $id,  read: true ){\n      \t_id\n        read\n    }\n   }\n  \n   ","variables":"{\"id\":\"fnEDo4l_-fACFAOeQbOlQAITZvAmjfJ0TU8pvlPCchzocPnq-FI\"}"}'


*/
