import { writable } from "svelte/store";
const notLoaded = false; // {}
const loggedOut = null;

const localStorageKey = "user";
import localforage from "localforage";

export let user = writable(notLoaded);

export const loadUser = async () => {
  let value;

  try {
    value = await localforage.getItem(localStorageKey);
  } catch (error) {
    console.log(error);
  }

  let set = false;
  if (value !== null) {
    console.log("got from local stoaagte user", value);
    let obj = JSON.parse(value);
    if (obj !== null && !Array.isArray(obj)) {
      console.log("setting user", value, Array.isArray(obj));
      set = true;
      user.set(obj);
    }
  }else { 
    console.log("no user in localforage", value)

  }
  return user;
  // if(!set){
  //   console.log("deleteing user", user)
  //   user.set(loggedOut);
  // }
};

user.subscribe(async (newValue) => {
  // have to check for notLoaded here as subscribe is always called once,
  // as soon as it is defined,
  // and this would overwrite localStorage before we had a chance to read it

  // if you set user to an empty object    $user.set({}) we remove if from localstorage\
  try {
    if (
      newValue &&
      Object.keys(newValue).length === 0 &&
      newValue.constructor === Object
    ) {
      console.log("deleteing user because of new value", user);
      localforage.removeItem(localStorageKey);
    } else if (newValue && newValue !== notLoaded) {
      const json = JSON.stringify(newValue);
      localforage.setItem(localStorageKey, json);
    }
  } catch (e) {
    console.log(e.message);
  }
});

const localStorageFeedsKey = "feeds";
let lastload;
const localStorageLastSaveKey = "lastload";

export let feeds = writable(notLoaded);

export const loadFeeds = async () => {
  let value;
  try {
    value = await localforage.getItem(localStorageFeedsKey);
    lastload = Number(await localforage.getItem(localStorageLastSaveKey));
  } catch (e) {
    console.log(e.message);
  }
  let clear = false;
  if (lastload) {
    let now = new Date().valueOf();
    console.log("last load", lastload, new Date(lastload).toString());
    let lag = 5 * 60 * 1000;
    if (location.hostname == "localhost") {
      lag = 60 * 60 * 24 * 1000; //on dev boxes we reload daily
    }
    if (lastload + lag < now) {
      console.log(
        "reloading feeds",
        lag,
        lastload,
        lastload + lag,
        now,
        new Date(lastload).toString(),
        new Date(lastload + lag).toString(),
        new Date(now).toString()
      );
      value = null;
    }
  }

  if (value !== null) {
    let loadedval = JSON.parse(value);
    console.log("loading feeds from LS2", loadedval);

    if (value == "{}" || value == "[]") {
      //Todo: remove this
      console.log("clearing bad feeds");
      feeds.set(null);
      clear = true;
    } else {
      feeds.set(loadedval);
    }
  } else {
    console.log("clearing feeds");
    feeds.set(null);
    clear = true;
  }
  if (lastload) {
    // this routine is not really loading the feed so were going to clober the new value ith the old
    try {
      if (feeds && !clear) {
        localforage.setItem(localStorageLastSaveKey, lastload);
      } else {
        localforage.setItem(localStorageLastSaveKey, null);
      }
    } catch (e) {
      console.log(e.message);
    }
  }
};

feeds.subscribe(async (newValue) => {
  // have to check for notLoaded here as subscribe is always called once,
  // as soon as it is defined,
  // and this would overwrite localStorage before we had a chance to read it
  try {
    if (localforage !== null && newValue && newValue !== notLoaded) {
      const value = localforage.setItem(
        localStorageLastSaveKey,
        new Date().valueOf()
      );

      const json = JSON.stringify(newValue);
      console.log("going to set feeds from LS", new Date().valueOf());

      try {
        localforage.setItem(localStorageFeedsKey, json);
        console.log("set feeds from LS", json);
      } catch (ex) {
        console.log("fail to set feeds from LS", ex);
      }
    }
  } catch (e) {
    console.log(e.message);
  }
  return 1;
});
