

<script>
  import { user } from "../_store.js";
  import { goto, url, meta } from "@sveltech/routify";
  //import 'bulma/css/bulma.css'
  import '@fortawesome/fontawesome-free/css/all.css'
  import wranglegraphql from  'wrangle-gql' 
  meta.title = "Login - Cowdog App";

  let email = "";
  let password = "";
  let progress = false;
  async function login(e) {
    if (e){e.preventDefault()};
    progress=true;
    //console.log("login wrangle", wranglegraphql);
    let vars = await wranglegraphql.loginCustomer( email, password );
    //$user=;
    //console.log("login loginCustomer",vars, )

    let userData = await  wranglegraphql.getUser(vars.data.loginCustomer);
    //console.log("login userData",vars, userData)

    userData.token = vars.data.loginCustomer;

    user.set(userData);


    //user.set({email: email, token: vars.data.loginCustomer});
    console.log("logged in ", $user, );
    /** We want to $goto our current location.
     *  Since we're now logged in, we shouldn't be redirected to this login page again.
     * **/
    // let nextpage = window.location.href ; // window.location.href.match('/app/login')
    // nextpage.replace('/app/login', '/app')
    // console.log("nextpage", nextpage, window.location.href);
    let nextpage = window.location.href ;
    if ($user.state == 'NEEDS_ADDRESS' ){ 
      console.log("needs addressa", user.addresses)
      if (!$user.addresses  ||  !$user.addresses.data || !$user.addresses.data.length){
        nextpage = '/app/address'
      }
    }


    console.log("nextpage",  $user.state ,window.location.href);
    $goto(nextpage);
  }
</script>
<style>
.login { max-width: 300px; margin: auto;  margin-top: 20vh; }

</style>




<div  class="login card" >
  <header class="card-header">
  <h1 class="card-header-title">Login</h1>
  </header>
  <div class="card-content" class:is-hidden={!progress}>
    <progress class="progress is-small is-primary" max="100">15%</progress>
  </div>
  <div class="card-content" class:is-hidden={progress}>
   <form  on:submit={login} >
      <div class="field">
        <label class="label">Email</label>
        <div class="control  has-icons-left">
          <input inputmode="email" name="email" id="email"  class="input" type="text" placeholder="email" autocomplete="email" required autofocus   bind:value={email}  />
          <span class="icon is-small is-left">
              <i class="fas fa-envelope"></i>
          </span>
        </div>
      </div>
      <div class="field">
        <label class="label">Password</label>
        <div class="control  has-icons-left">
          <input required class="input" type="password" name="password"  id="password" placeholder="password" autocomplete="password"  bind:value={password} />
          <span class="icon is-small is-left">
            <i class="fas fa-lock"></i>
          </span>
        </div>
      </div>

      <div class="field">
        <div class="control">
            <button type="submit" class="input button  is-primary">Submit</button>
        </div>
      </div>
    </form>
  </div>
</div>


