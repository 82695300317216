import HMR from  '@sveltech/routify/hmr'
import App from './App.svelte';
console.log("main");
if ('serviceWorker' in navigator) {
    // Use the window load event to keep the page load performant
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js');
    });
  }

const app = HMR(App, { target: document.body }, 'routify-app');
document.getElementById('splash').style.display = 'none';
console.log("main done");


// Check that service workers are supported

export default app;
