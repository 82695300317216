

<script>
 import { params, meta, goto } from '@sveltech/routify'  
 import { user } from "../_store.js";

 let email;
 meta.title = "Create a CowDog Email - Cowdog App";
 import wranglegraphql from  'wrangle-gql' 

 function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
}
 let random = uuidv4()
 let created=false;
 let error="";
  let progress;
  let create_error;



async function  createAddress(e) {
    if (e){e.preventDefault()};
    if (email.length < 5){
        error = "Email must be 5 characters long"
        return;
     }    
     if (email && !email.match(/^[\w\.]+$/)){
        error = 'Email must only contain letters and numbers.'
        return;
     }
     if (!validateEmail){
         return;
     }
    error = '';
    progress=true;
    console.log("createAddress wreangle", $user.token,  email, wranglegraphql);
    let formatted_email = email.toLowerCase() + '@cowdog.email'
    let vars = await wranglegraphql.createAddress($user.token, formatted_email );

    console.log("createAddress result", $user, vars, formatted_email);

    console.log("postgraphql" , vars,);
      if (!vars || vars.errors){
          console.log("errors" , vars.errors[0].message);
          create_error= vars.errors[0].message;
          if ( 'Instance is not unique.' == create_error )      {
              create_error = 'Address is already taken';
          }




      }else{
        //TODO just alter
         if ($user  && $user.addresses && $user.addresses.data ){
            $user.addresses.data.push( {email: formatted_email } )          
        }else  {
            $user.addresses = {data: [{email: formatted_email } ]}
        }
        // let userData = await  wranglegraphql.getUser($user.token);
        // userData.token = $user.token;
        // user.set(userData);
        progress = false;
        created= true;
      }






   
  }
  
function validateEmail (){
    if (email && !email.match(/^[\w\.\-]+$/)){
        error = 'Email must only contain letters numbers, and dashes "-".'
    }else{
        error = ''
    }
    if (error){
        //console.log("validateing", email, error)
    }   
    return (error ? 0 : 1)

}

$: console.log("error",error)
$: validateEmail(email);
$: console.log("email", email)
</script>

<style>
.confirmbox {

width: 80%; max-width:800px; margin:auto; margin-top: 20vh;
}
.emailcontrol { display: flex;}
#id {flex-grow: 1;}
code.email{
background-color: whitesmoke;
color: #4a4a4a;;
font-weight: normal;
display: inline-block;
padding: 0;

}

.control code.email{
line-height: 40px;
background-color: whitesmoke;
color: #4a4a4a;;
font-weight: normal;
height: 40px;
padding: 0 .5em 0 .25em;
display: inline-block;


}
.error {margin-top: 10px;
padding: 1em 0;
}

</style>


<div  class="confirm card confirmbox " style="width: 80%; max-width:800px; margin:auto; margin-top: 20vh;"   class:is-hidden={create_error || created}>
  <header class="card-header">
  <h1 class="card-header-title">    <a href = "/app"><i
                  class="fas fa-chevron-left backup"
                   /></a> &nbsp    <a href = "/app">Create an Address</a></h1>
  </header>
  <div class="card-content" class:is-hidden={!progress}>
    <progress class="progress is-small is-primary" max="100">15%</progress>
  </div>
  <div class="card-content" class:is-hidden={created || create_error}>
    <p style="margin-bottom: 30px;">To get started wrangling your email you'll need a  <code class="email" style="font-size: 16px">@cowdog.email</code> address to forward your newsletters to - and to give out to any new mailing list you might join.  Some people like something truely random like <code>{random}</code> for privacy sakes, but most just choose something they can remember.</p>
   <form  on:submit|preventDefault={createAddress} >
      <div class="field">
        <label class="label">Choose an Email address</label>
        <div class="control  has-icons-left emailcontrol">
          <input inputmode="email" name="email" id="email"  class="input" type="text" placeholder="myemail"  required autofocus   on:change={e => validateEmail(e)} bind:value={email}  />
          <span class="icon is-small is-left">
              <i class="fas fa-envelope"></i>
          </span> 
          <code class="email" style="font-size: 16px">@cowdog.email</code>
        </div>
        <div class="error has-text-danger"  is-hidden={!error}  > {error} </div>
      </div>
      

      <div class="field">
        <div class="control">
            <button type="submit" class="input button  is-primary" disabled={error}>Submit</button>
        </div>
      </div>
    </form>
  </div>
</div>


<article class="message is-success confirmbox"  class:is-hidden={!created}>
  <div class="message-header">
    {email}@cowdog.email Created!
   
  </div>
  <div class="message-body">
    <p>You can now start forwarding your mail to {email}@cowdog.email and it will show up in the app.  You can also just give out the address wehn ever you sign up for a newsletter or mailing list</p>
    <div> 
        <div class="field is-grouped is-grouped-right">
            <div class="control">
                <button class="button is-link" on:click={e => {$goto('/app')} }>Go to the App</button>
            </div>
        </div>   
    </div>
  </div>
</article>



<article class="message is-danger confirmbox"  class:is-hidden={!create_error}>
  <div class="message-header">
    <p>Error</p>
   
  </div>
  <div class="message-body">
    <p>{create_error}  </p>
    <div> 
        <div class="field is-grouped is-grouped-right">
            <div class="control">
                <button class="button is-link" on:click={e => {create_error = '' ;created=false; progress=false;} }> Try Again</button>
            </div>
        </div>   
    </div>
  </div>
</article>

    