<script>
  import { goto, url } from "@sveltech/routify";
  import { user, loadUser } from "./_store";
  import 'bulma/css/mystyles.css'
  import '@fortawesome/fontawesome-free/css/all.css'

  /** We set the static parameret to true since we don't want to change the browser's URL
   *  Notice the $: prefix swhich makes the statement reactive. This way if the user logs 
   *  out the $goto is called again.
   * **/
  console.log("reset loadUser1!"); 
 

  $: if (!$user || ! $user.token)  {  
      loadUser().then( () => {  console.log("reset post loadUser!", $user);  if (!$user || ! $user.token)  {   console.log("no username going to login", $user);   $goto("./login", {}, true);  } }       ).catch (e=> {console.log("loaduser error", e) ; })  
     }

  function logout() {
    $user = false;
  }
</script>

{#if $user}
  <slot>
    <!-- optional fallback -->
  </slot>
{/if}